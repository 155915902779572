import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import "alba";
import "@/scss/estilosGenerales.scss"
//FontAwesome 
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
//V-Calendar
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

library.add(faCalendarAlt)

const pinia = createPinia()
const app = createApp(App)
app
    .use(router)
    .use(pinia)
    .use(VCalendar,{})
    .component('font-awesome-icon',FontAwesomeIcon)
    .mount('#app')

