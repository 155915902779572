
import { defineComponent } from 'vue';
import NavBar from '@/components/NavBar.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default defineComponent({
  components: {
    NavBar,
    FooterComponent,
  },
});
