import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "marketPlace",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MarketPlace.vue"),
  },
  {
    path: "/planAuto",
    name: "planAuto",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PlanAuto.vue"),
  },
  {
    path: "/cotizador",
    name: "cotizador",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CotizadorView.vue"),
  },
  {
    path: "/cotizacion",
    name: "cotizacion",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CotizacionView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/gracias",
    name: "gracias",
    component: () =>
      import(/* webpackChunkName: "Gracias" */ "../views/GraciasView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

export default router;
